import { errorHandler, formatDate } from '@/api/utils'
import { http } from '@/plugins/ky'

export type InvoiceMeta = {
  id: string
  date: string
}

export const get = () =>
  new Promise<InvoiceMeta[]>((resolve, reject) =>
    http
      .get('customer/payment/invoices')
      .then(async (r: any) => {
        const result = await r.json()
        resolve(
          result.map(({ id, date }: any) => {
            return {
              id,
              date: formatDate(date),
            }
          })
        )
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject([])
      })
  )
