import { errorHandler, createInvoice } from '@/api/utils'
import { http } from '@/plugins/ky'
import { Invoice } from '@/types'

export const get = (billingId: string) =>
  new Promise<Invoice>((resolve, reject) =>
    http
      .get('customer/payment/the-invoice', {
        searchParams: {
          id: billingId,
        },
      })
      .then(async (r: any) => {
        const result = await r.json()
        resolve(createInvoice(result))
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject(undefined)
      })
  )
