
import { get as getInvoice } from '@/api/customer/payment/invoice'
import {
  get as getInvoices,
  InvoiceMeta,
} from '@/api/customer/payment/invoices'
import { get as getNextMonthInvoice } from '@/api/customer/payment/nextMonth'
import { Select } from '@/api/types'
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  toRefs,
} from 'vue'
import { useTranslation } from '@/locales'
import { useRoute } from 'vue-router'
import { serviceFactory } from '@/service'
import { InvoiceView, InvoiceDetails } from '@/types'

export default defineComponent({
  setup() {
    const selectItem = reactive<{ selected: InvoiceView | undefined }>({
      selected: undefined,
    })
    const { selected } = toRefs(selectItem)
    const billingsItem = reactive<{ billings: InvoiceMeta[] }>({ billings: [] })
    const { billings } = toRefs(billingsItem)
    const isOpen = ref(false)
    const selector = ref<HTMLDivElement | null>(null)
    const options = ref<HTMLButtonElement | null>(null)
    const noBilling = ref('')
    const { t, d, n } = useTranslation()
    const title = ref('')
    const nextMonthMeta = { id: '', date: t('Next month') }
    const route = useRoute()
    const { query } = route

    const openInvoices = () => {
      isOpen.value = !isOpen.value
    }

    const dropDown = (event: any) => {
      if (selector.value != null && !selector.value.contains(event.target)) {
        if (options.value != null && !options.value.contains(event.target)) {
          openInvoices()
        }
      }
    }

    const select = (billing: InvoiceMeta) => {
      isOpen.value = false

      if (billing.id === nextMonthMeta.id) {
        title.value = t('Billing for the next month')
        return getNextMonthInvoice()
          .then((res) => {
            const { date, createdAt, completed, ...rest } = res

            const result = {
              date: nextMonthMeta.date,
              completed: t('Forcast'),
              createdAt: d(createdAt, 'ymdhms') + t('As point of'),
              ...rest,
            } as InvoiceView

            return result
          })
          .catch((res) => res)
      } else {
        title.value = t('Billing')

        return getInvoice(billing.id)
          .then((res) => {
            const { date, createdAt, ...rest } = res
            console.log(res)
            return {
              date: d(date, 'ymdhms'),
              createdAt: d(createdAt, 'ymdhms'),
              ...rest,
            } as InvoiceView
          })
          .catch((res) => res)
      }
    }

    const changeBilling = async (billing: InvoiceMeta) => {
      const res = await select(billing)

      res.details = res.details.map((detail: InvoiceDetails) => {
        const {
          name,
          serviceCategory,
          amount,
          startDate,
          expireDate: endDate,
          paymentFrequency,
          identifier,
          ...rest
        } = detail

        const serviceManager = serviceFactory(detail.serviceCategory, t, d)

        return {
          name: serviceManager.getServiceName(
            amount,
            startDate,
            endDate,
            paymentFrequency,
            identifier
          ),
          ...rest,
        }
      })

      selected.value = res
    }

    onBeforeMount(async () => {
      selected.value = await select(nextMonthMeta)

      if (selected.value !== undefined) {
        billings.value = [nextMonthMeta]
      }
      const result = await getInvoices()

      if (result.length > 0) {
        billings.value = billings.value.concat(result)
        if (selected.value === undefined) {
          selected.value = await select(result[0])
        }
      }

      noBilling.value = t('No billings now')

      if (query.id != null) {
        changeBilling({ id: query.id as string } as InvoiceMeta)
      }
    })

    onMounted(() => window.addEventListener('click', dropDown))

    onBeforeUnmount(() => window.removeEventListener('click', dropDown))

    return {
      selected,
      billings,
      selector,
      options,
      noBilling,
      title,
      isOpen,
      t,
      d,
      n,
      select,
      openInvoices,
      changeBilling,
    }
  },
})
