<template>
  <div
    v-if="selected !== undefined"
    class="container md:mx-auto md:mt-5 text-left pt-2 md:max-w-screen-lg"
  >
    <h1
      class="text-lg md:text-xl mb-4 text-gray-500 font-bold border-b-2 border-blue-300"
    >
      {{ title }}
    </h1>
    <!-- <div class="flex mb-4 justify-items-center"></div> -->
    <div class="flex justify-between items-center">
      <p class="text-left text-xs text-gray-500">{{ t('Billing to') }}</p>
      <div class="relative inline-block text-left">
        <div>
          <button
            ref="options"
            type="button"
            class="inline-flex items-center justify-center w-full border-none px-4 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            aria-expanded="true"
            aria-haspopup="true"
            @click="openInvoices"
          >
            {{ selected.date }}

            <!-- Heroicon name: solid/chevron-down -->
            <svg
              class="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          v-if="isOpen"
          ref="selector"
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div class="py-1" role="none">
            <p
              v-for="(billing, index) in billings"
              :key="index"
              class="px-2 cursor-pointer hover:bg-gray-200"
              :class="{ selected: billing.date === selected.date }"
              @click="changeBilling(billing)"
            >
              {{ billing.date }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <h1 class="text-left mb-1 text-2xl font-bold">
      {{ selected.customerName }}
    </h1>

    <p class="text-xs text-gray-500 mb-1 text-left">
      {{ selected.customerAddress }}
    </p>
    <p class="text-xs text-gray-500 mb-1 text-left">
      {{ t('Issue Date') }} {{ selected.createdAt }}
    </p>
    <p class="text-xs text-gray-500 mb-1 text-left">
      {{ t('Payment period') }} {{ selected.paymentLimitDate }}
    </p>

    <p class="text-xs text-gray-500 mb-8 text-left">
      {{ t('Status') }} {{ t(selected.completed) }}
    </p>

    <table class="border-b-2">
      <tr class="text-left border-b-2">
        <th class="py-2 text-xs md:text-base">{{ t('Service') }}</th>
        <th class="py-2 text-xs md:text-base">{{ t('Unit price') }}</th>
        <th class="py-2 text-xs md:text-base">{{ t('Amount') }}</th>
        <th class="py-2 text-xs md:text-base">{{ t('Total amount') }}</th>
      </tr>

      <tr v-for="(item, index) in selected.details" :key="index">
        <td class="w-96 py-2 text-left text-xs md:text-base">
          {{ item.name }}
        </td>
        <td class="w-60 py-2 text-left text-xs md:text-base">
          {{ n(item.ratePrice, 'currency') }}
        </td>
        <td class="w-60 py-2 text-left text-xs md:text-base">
          {{ item.amount }}
        </td>
        <td class="w-60 py-2 text-left text-xs md:text-base">
          {{ n(item.total, 'currency') }}
        </td>
      </tr>
    </table>

    <p class="mt-1 mb-20 text-left text-xs text-gray-500">
      {{
        t(
          'All dates are displayed and calculated in UTC (Coordinated Universal Time).'
        )
      }}
    </p>

    <div class="flex justify-center md:justify-end items-center">
      <div class="">
        <div class="flex mb-6">
          <div class="">
            <h2 class="w-32 text-left mb-2">{{ t('Subtotal') }}：</h2>
            <h2 class="w-32 text-left mb-2">{{ t('Sales tax') }}：</h2>
            <h2 class="w-32 text-left mb-2 font-bold">
              {{ t('Total amount with tax') }}:
            </h2>
          </div>
          <div class="">
            <p class="text-right mb-2">{{ n(selected.total, 'currency') }}</p>
            <p class="text-right mb-2">
              {{ n(selected.salesTax, 'currency') }}
            </p>
            <p class="text-right font-bold mb-2">
              {{ n(selected.totalWithTax, 'currency') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-left">
      <h2 class="text-gray-700">{{ t('Qualitia.co.,Ltd') }}</h2>
      <p class="text-xs md:text-sm text-gray-500 mb-1">
        {{
          t('3-11-10, Nihombashikayabacho, Chuo Ku, Tokyo To, 103-0025, Japan')
        }}
      </p>
      <div class="text-xs md:text-sm flex text-gray-500">
        <p class="w-10">{{ t('Phone') }}:</p>
        <p>{{ t('+81-3-5623-2530') }}</p>
      </div>
    </div>
  </div>
  <div v-else class="text-2xl font-bold text-gray-600 mt-10">
    <h1>{{ noBilling }}</h1>
  </div>
</template>

<script lang="ts">
import { get as getInvoice } from '@/api/customer/payment/invoice'
import {
  get as getInvoices,
  InvoiceMeta,
} from '@/api/customer/payment/invoices'
import { get as getNextMonthInvoice } from '@/api/customer/payment/nextMonth'
import { Select } from '@/api/types'
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  toRefs,
} from 'vue'
import { useTranslation } from '@/locales'
import { useRoute } from 'vue-router'
import { serviceFactory } from '@/service'
import { InvoiceView, InvoiceDetails } from '@/types'

export default defineComponent({
  setup() {
    const selectItem = reactive<{ selected: InvoiceView | undefined }>({
      selected: undefined,
    })
    const { selected } = toRefs(selectItem)
    const billingsItem = reactive<{ billings: InvoiceMeta[] }>({ billings: [] })
    const { billings } = toRefs(billingsItem)
    const isOpen = ref(false)
    const selector = ref<HTMLDivElement | null>(null)
    const options = ref<HTMLButtonElement | null>(null)
    const noBilling = ref('')
    const { t, d, n } = useTranslation()
    const title = ref('')
    const nextMonthMeta = { id: '', date: t('Next month') }
    const route = useRoute()
    const { query } = route

    const openInvoices = () => {
      isOpen.value = !isOpen.value
    }

    const dropDown = (event: any) => {
      if (selector.value != null && !selector.value.contains(event.target)) {
        if (options.value != null && !options.value.contains(event.target)) {
          openInvoices()
        }
      }
    }

    const select = (billing: InvoiceMeta) => {
      isOpen.value = false

      if (billing.id === nextMonthMeta.id) {
        title.value = t('Billing for the next month')
        return getNextMonthInvoice()
          .then((res) => {
            const { date, createdAt, completed, ...rest } = res

            const result = {
              date: nextMonthMeta.date,
              completed: t('Forcast'),
              createdAt: d(createdAt, 'ymdhms') + t('As point of'),
              ...rest,
            } as InvoiceView

            return result
          })
          .catch((res) => res)
      } else {
        title.value = t('Billing')

        return getInvoice(billing.id)
          .then((res) => {
            const { date, createdAt, ...rest } = res
            console.log(res)
            return {
              date: d(date, 'ymdhms'),
              createdAt: d(createdAt, 'ymdhms'),
              ...rest,
            } as InvoiceView
          })
          .catch((res) => res)
      }
    }

    const changeBilling = async (billing: InvoiceMeta) => {
      const res = await select(billing)

      res.details = res.details.map((detail: InvoiceDetails) => {
        const {
          name,
          serviceCategory,
          amount,
          startDate,
          expireDate: endDate,
          paymentFrequency,
          identifier,
          ...rest
        } = detail

        const serviceManager = serviceFactory(detail.serviceCategory, t, d)

        return {
          name: serviceManager.getServiceName(
            amount,
            startDate,
            endDate,
            paymentFrequency,
            identifier
          ),
          ...rest,
        }
      })

      selected.value = res
    }

    onBeforeMount(async () => {
      selected.value = await select(nextMonthMeta)

      if (selected.value !== undefined) {
        billings.value = [nextMonthMeta]
      }
      const result = await getInvoices()

      if (result.length > 0) {
        billings.value = billings.value.concat(result)
        if (selected.value === undefined) {
          selected.value = await select(result[0])
        }
      }

      noBilling.value = t('No billings now')

      if (query.id != null) {
        changeBilling({ id: query.id as string } as InvoiceMeta)
      }
    })

    onMounted(() => window.addEventListener('click', dropDown))

    onBeforeUnmount(() => window.removeEventListener('click', dropDown))

    return {
      selected,
      billings,
      selector,
      options,
      noBilling,
      title,
      isOpen,
      t,
      d,
      n,
      select,
      openInvoices,
      changeBilling,
    }
  },
})
</script>
<style scoped lang="postcss">
.selected {
  @apply bg-blue-200;
}
</style>
